.calendarApp {
  margin-bottom: 80px;
  padding-top: 0px;
  padding-bottom: 60px;
}

.calendarApp .body {
  max-width: 1000px;
  margin: auto;
  padding: 20px 0;
}

.calendarApp h1 {
  font-family: 'Roboto Black';
  font-size: 45px;
  line-height: 60px;
  color: #151C45;
  margin-top: 20px;
  margin-bottom: 5px;
  padding-right: 40px;
}

.calendarApp h2 {
  font-family: 'Roboto Regular';
  font-size: 26px;
  line-height: 40px;
  color: #151C45;
  margin-bottom: 40px;
  padding-right: 40px;
  font-weight: 500;
}

.calendarApp .item {
  margin-bottom: 20px;
}

.calendarApp img {
  max-height: 650px;
}

.calendarApp .icon {
  width: 27px;
  height: 27px;
}

.calendarApp span {
  font-family: 'Roboto Regular';
  font-size: 18px;
  margin-left: 20px;
  color: #151C45;
}


@media (max-width: 768px) {
  .calendarApp .body {
    padding: 0 30px;
  }

  .calendarApp h1 {
    font-family: 'Roboto Bold';
    text-align: center;
    font-size: 35px;
    line-height: 45px;
    margin: 40px 0 0 0;
    padding-right: 0;
  }

  .calendarApp h2 {
    font-family: 'Roboto Medium';
    text-align: center;
    font-size: 22px;
    line-height: 35px;
    margin: 30px 0 50px 0;
    padding: 0px 30px;
  }

  .calendarApp .item {
    padding-left: 20px;
  }

  .calendarApp img.mobile {
    width: 85%;
    height: auto;
    margin: 40px auto;
  }

  .calendarApp span {
    flex: 1;
    font-size: 22px;
  }

}