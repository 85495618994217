.homeApp {
  margin-bottom: 80px;
  padding-top: 0px;
  padding-bottom: 60px;
}

.homeApp .body {
  max-width: 1000px;
  margin: auto;
  padding: 20px 0;
}

.homeApp h1 {
  font-family: 'Roboto Black';
  font-size: 45px;
  line-height: 60px;
  color: #151C45;
  padding-right: 40px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.homeApp h2 {
  font-family: 'Roboto Regular';
  font-size: 26px;
  line-height: 40px;
  color: #151C45;
  margin-bottom: 60px;
  padding-right: 50px;
  font-weight: 500;
}

.homeApp .stores {
  margin-right: auto;
}
.homeApp .store {
  width: 150px;
  height: 50px;
  padding-right: 20px;
}

.homeApp span {
  display: block;
  font-family: 'Roboto Italic';
  font-size: 16px;
  line-height: 26px;
  color: #017D9B;
  margin-top: 60px;
  padding-right: 60px;
  font-weight: 500;
}

.homeApp img {
  height: 650px;
}

@media (max-width: 768px) {
  .homeApp .body {
    padding: 0 20px;
  }

  .homeApp h1 {
    font-family: 'Roboto Bold';
    text-align: center;
    font-size: 35px;
    line-height: 45px;
    margin: 40px 0 0 0;
    padding-right: 0;
  }

  .homeApp h2 {
    font-family: 'Roboto Medium';
    text-align: center;
    font-size: 22px;
    line-height: 35px;
    margin: 30px 0 50px 0;
    padding: 0px 30px;
  }

  .homeApp img {
    width: 85%;
    height: auto;
    margin-bottom: 60px;
  }

  .homeApp .stores {
    margin-right: 0;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }
  .homeApp .store {
    padding-right: 0px;
   
  }

  .homeApp span {
    display: block;
    font-size: 22px;
    line-height: 32px;
    margin-top: 0px;
    padding: 0px 20px;
    text-align: center;
  }

}