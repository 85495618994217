.footer {
    background-color: #1E0F57;
    padding-top: 30px;
}

.footer>.container_text,
.footer>.footer_list {
    max-width: 1160px;
    margin: auto;
    padding: 0 20px;
}

.footer>.container_text h1,
.footer>.container_text p {
    color: #FFFFFF;
    text-align: center;
}

.footer>.container_text>.download>.stores {
    display: flex;
    padding-top: 40px;
    justify-content: center;
}

.footer>.container_text>.download {
    width: 600px;
    margin: auto;
}

.footer>.container_text .download>div {
    max-width: 500px;
    margin: auto;
}

.footer>.footer_list {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}



.footer>.footer_list .logo_white {
    margin-bottom: 15px;
    height: 70px;
}

.footer>.footer_list>.info_list {
    width: 570px;
}

.footer>.footer_list>.info_list>div>p {
    color: #AAAAAA;
    line-height: 21px;
}

.footer>.copyright {
    max-width: 532px;
    margin: auto;
    padding: 100px 0;
}

.footer>.copyright>p {
    color: #777777;
    text-align: center;
}

.footer>.footer_list>div>.rrss>img {
    width: 45px;
    height: 45px;
    margin-right: 10px;
    cursor: pointer;
}

@media (max-width: 768px) {

    .footer {
        border-top-right-radius: 0px 0px;
        padding-top: 50px;
    }

    .footer>.footer_list {
        max-width: 265px;
        margin: auto;
        padding: 0px;
    }

    .footer>.footer_list>.info_list {
        width: auto;
        margin: auto;
    }

    .footer>.footer_list .logo_white {
        height: 50px;
    }

    .footer>.footer_list {
        flex-direction: column;
        text-align: center;
    }

    .column_list>p {
        margin-bottom: 10px;
    }

    .column_list>ul {
        margin-top: 0px !important;
    }

    .column_list>ul>li {
        font-size: 15px;
    }

    .footer>.footer_list>div>.rrss {
        margin-top: 40px;
    }

    .footer>.copyright {
        width: 350px;
        padding-top: 30px;
        padding-bottom: 70px;
    }

    .copyright>p {
        font-size: 12px;
        display: flex;
        justify-content: center;
    }

    .copyright span {
        margin-right: 3px;
    }


}