.parentsBenefits {
  max-width: 1200px;
  margin: auto;
  margin-bottom: 120px;
}

.parentsBenefits h1 {
  font-family: 'Roboto Bold';
  font-size: 48px;
  color: #03144A;
  margin-bottom: 30px;
}

.parentsBenefits h2 {
  font-family: 'Roboto Regular';
  font-size: 20px;
  line-height: 32px;
  color: #696871;
  margin-bottom: 60px;
  max-width: 1100px;
  font-weight: 500;
}

.parentsBenefits .card {
  padding: 30px 42px 60px 42px;
  border-radius: 25px;
}

.parentsBenefits .card:hover {
  background-color: #FAF8FF;
}

.parentsBenefits .card-image {
  width: 105px;
  height: 105px;
}

.parentsBenefits .card-title {
  font-family: 'Roboto Bold';
  font-size: 20px;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #03144A;

}

.parentsBenefits .card-description {
  font-family: 'Roboto Regular';
  font-size: 17px;
  line-height: 26px;
  color: #03144A;
}

@media (max-width: 768px) {
  .parentsBenefits {
    padding: 0 20px;
  }

  .parentsBenefits h1 {
    text-align: center;
    font-size: 35px;
  }
  
  .parentsBenefits h2 {
    text-align: center;
    font-size: 20px;
    /* font-size: 30px; */
  }

  .parentsBenefits .card {
    padding: 25px 30px;
    margin: 0 30px;
    min-height: auto;
  }

  .parentsBenefits .card-title {
    font-size: 23px;
  }

  .parentsBenefits .card-description {
    font-size: 19px;
    line-height: 30px;
  }
}