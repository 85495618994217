.dashboard {
  margin-bottom: 80px;
  padding-top: 0px;
  padding-bottom: 60px;
}

.dashboard .body {
  max-width: 1100px;
  margin: auto;
  padding: 20px 0;
}

.dashboard h1 {
  font-family: 'Roboto Black';
  font-size: 45px;
  line-height: 60px;
  color: #151C45;
  padding-right: 60px;
  margin-bottom: 5px;
}

.dashboard h2 {
  font-family: 'Roboto Regular';
  font-size: 26px;
  line-height: 40px;
  color: #151C45;
  margin-bottom: 60px;
  padding-right: 40px;
  font-weight: 500;
}

.dashboard img {
  height: 550px;
}

.dashboard button {
  font-family: 'Roboto Regular';
  font-size: 19px;
  padding: 12px 37px;
  background-color: #FFFFFF;
  color: #03124B;
  cursor: pointer;
  border-radius: 12px;
  border: 2px solid #03124B;
  box-shadow: none;
  margin-right: auto;
  text-transform: none;
}


.dashboard button:hover {
  background-color: #FFFFFF !important;
}


@media (max-width: 768px) {
  .dashboard {
    margin: 80px auto;
    padding: 0 20px;
  }

  .dashboard h1 {
    font-family: 'Roboto Bold';
    text-align: center;
    font-size: 35px;
    line-height: 45px;
    margin-top: 0px;
    margin-bottom: 40px;
    padding-right: 0px;
  }

  .dashboard img {
    padding: 0;
    width: 90%;
    margin: auto;
  }

  .dashboard h2 {
    font-family: 'Roboto Medium';
    text-align: center;
    font-size: 22px;
    line-height: 35px;
    margin: 30px 0;
    padding: 0px 10px;
    margin-bottom: 40px;
  }

  .dashboard button {
    font-family: 'Roboto Regular';
    margin: auto;
    padding: 10px 30px;
    box-shadow: none;
  }
}