.reviews {
  max-width: 1200px;
  margin: 150px auto;
}

.reviews h1 {
  text-align: center;
  font-family: 'Roboto Bold';
  font-size: 45px;
  line-height: 60px;
  color: #03144A;
  max-width: 460px;
  margin: auto;
  margin-bottom: 0px;
}
.reviews h1 img {
  margin: 0 15px;
  width: 80px;
  height: 35px;
}

.reviews h2 {
  text-align: center;
  font-family: 'Roboto Bold';
  font-size: 20px;
  color: #03144A;
  margin-bottom: 40px;
  background-color: #F3F3F6;
  padding: 20px 0;
  border-radius: 10px;
  margin-top: 80px;
}


.reviews .card {
  padding: 50px 45px;
  border-radius: 25px;
}

.reviews .card-image {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.reviews .card-name {
  display: block;
  font-family: 'Roboto Bold';
  font-size: 18px;
  color: #03144A;

}
.reviews .card-location {
  font-family: 'Roboto Regular';
  font-size: 13px;
  color: #696871;

}

.reviews .card-description {
  font-family: 'Roboto Regular';
  font-size: 17px;
  line-height: 30px;
  color: #696871;
  flex-grow: 1;
}

@media (max-width: 768px) {
  .reviews {
    padding: 0 20px;
    margin: 100px auto;
  }

  .reviews h1 {
    text-align: center;
    font-size: 35px;
  }

  .reviews h1 img {
    margin: 0 15px;
    width: 75px;
    height: 30px;
  }

  .reviews .card {
    padding: 40px;
    margin: 0 10px 30px 10px;
    min-height: auto;
  }

  .reviews .card-image {
    width: 70px;
    height: 70px;
    margin-right: 15px;
  }

  .reviews .card-name {
    font-size: 25px;
  }

  .reviews .card-location {
    font-size: 20px;
  
  }

  .reviews .card-description {
    font-size: 20px;
    line-height: 32px;
    padding-top: 50px;
  }
}