.intro {
  max-width: 1200px;
  margin: 150px auto;
}

.intro .MuiGrid-item {
  margin: auto;
}

.intro h1 {
  font-family: 'Roboto Black';
  font-size: 60px;
  line-height: 75px;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #03124B;
  padding-right: 10px;
}

.intro h2 {
  font-family: 'Roboto Regular';
  font-size: 25px;
  line-height: 40px;
  color: #03124B;
  margin-bottom: 60px;
  padding-right: 100px;
  font-weight: 500;
}

.intro img {
  max-height: 580px;
}

.intro .button {
  font-family: 'Roboto Regular';
  font-size: 19px;
  padding: 12px 37px;
  border: none;
  background-color: #403083;
  color: #FFFFFF;
  cursor: pointer;
  border-radius: 12px;
  text-transform: none;
}

.intro .button:hover {
  background-color: #403083 !important;
}

.intro .play-icon {
  margin-left: 40px;
  height: 60px;
  width: 60px;
  cursor: pointer;
  box-shadow: 2px 3px 6px #5757572f;
  border-radius: 50px;
}
.intro .play-icon:hover {
  box-shadow: 4px 6px 15px #5757572f;
}

@media (max-width: 768px) {
  .intro {
    margin: 100px auto;
    padding: 0 20px;
  }

  .intro h1 {
    text-align: center;
    font-size: 40px;
    line-height: 50px;
    margin-top: 20px;
    margin-bottom: 40px;
    padding-right: 0px;
  }

  .intro img {
    padding: 0;
    width: 90%;
    margin: auto;
  }

  .intro h2 {
    text-align: center;
    font-size: 21px;
    line-height: 35px;
    padding-right: 0px;
    margin-bottom: 40px;
  }

  .intro button {
    display: block;
    margin: auto;
  }
  .intro .play-icon {
    margin: 40px auto 0px auto;
  }
}