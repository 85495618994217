
* {
  font-family: "Roboto", sans-serif;
}

.App .content {
  margin-top: 90px
}


.App .heading,
.App .caption {
  color: #03144A;
}

.App .heading {
  font-size: 70px;
}

.App .subtitle {
  font-size: 50px;
  color: #03144A;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 30px;
  /* letter-spacing: 1.3px; */
  line-height: 65px;
}

.caption {
  font-size: 26px;
  font-weight: 400;
  max-width: 850px;
  line-height: 38px;
}


.App .description-subtitle {
  color: #03144A;
  font-size: 25px;
  font-weight: 450;
  margin-top: 0px;
  margin-bottom: 30px;
  line-height: 36px;
}

.App .note {
  font-style: italic;
  font-size: 16px;
  color: #017D9B;
  line-height: 30px;
  margin-top: 0px;
  margin-bottom: 30px;
}

.App .email-link {
  cursor: pointer;
  color: #017D9B;
}

.App .description {
  color: #7a7982;
  font-size: 20px;
  line-height: 31px;
  margin-top: 0px;
  margin-bottom: 30px;
}

.mobile {
  display: none !important;
}

.show-button {
  border: solid 1px #2C3871 !important;
  border-radius: 12px !important;
  height: 45px;
  color: #151C45 !important;
  text-transform: none !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  width: 230px;
  margin-top: 20px;
}



@media (max-width: 768px) {

  html,
  body {
    overflow-x: hidden;
  }

  body {
    position: relative
  }

  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

  .App .content {
    margin-top: 75px;
  }

  .heading {
    font-size: 35px !important;
    margin-top: 10px;
  }

  .App .subtitle {
    font-size: 30px;
    line-height: 40px;
  }

  .App .description {
    font-size: 17px;
  }

  .App .caption {
    font-size: 17px;
    line-height: 26px;
    margin-top: 0px;
  }

  .App .description-subtitle {
    font-size: 20px;
    line-height: 30px;
  }

}