body {
  margin: 0;
}
@font-face{
  font-family: 'Roboto Regular';
  src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: 'Roboto Bold';
  src: url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face{
  font-family: 'Roboto Italic';
  src: url('./assets/fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face{
  font-family: 'Roboto Bold Italic';
  src: url('./assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face{
  font-family: 'Roboto Light';
  src: url('./assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face{
  font-family: 'Roboto Light Italic';
  src: url('./assets/fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face{
  font-family: 'Roboto Medium';
  src: url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face{
  font-family: 'Roboto Medium Italic';
  src: url('./assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face{
  font-family: 'Roboto Thin';
  src: url('./assets/fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face{
  font-family: 'Roboto Thin Italic';
  src: url('./assets/fonts/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face{
  font-family: 'Roboto Black';
  src: url('./assets/fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face{
  font-family: 'Roboto Black Italic';
  src: url('./assets/fonts/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face{
  font-family: 'SFUIText Bold';
  src: url('./assets/fonts/SFUIText-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face{
  font-family: 'SFUIText Bold Italic';
  src: url('./assets/fonts/SFUIText-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face{
  font-family: 'SFUIText Heavy';
  src: url('./assets/fonts/SFUIText-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face{
  font-family: 'SFUIText Heavy Italic';
  src: url('./assets/fonts/SFUIText-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face{
  font-family: 'SFUIText Light';
  src: url('./assets/fonts/SFUIText-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face{
  font-family: 'SFUIText Light Italic';
  src: url('./assets/fonts/SFUIText-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face{
  font-family: 'SFUIText Medium';
  src: url('./assets/fonts/SFUIText-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face{
  font-family: 'SFUIText Medium Italic';
  src: url('./assets/fonts/SFUIText-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face{
  font-family: 'SFUIText Regular';
  src: url('./assets/fonts/SFUIText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: 'SFUIText Regular Italic';
  src: url('./assets/fonts/SFUIText-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face{
  font-family: 'SFUIText Semibold';
  src: url('./assets/fonts/SFUIText-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face{
  font-family: 'SFUIText Semibold Italic';
  src: url('./assets/fonts/SFUIText-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
