.school {
  margin-bottom: 80px;
  background-color: #F4F2FA;
  padding-top: 80px;
  padding-bottom: 60px;
}

.school .body {
  max-width: 1200px;
  margin: auto;
  padding: 20px 0;
}

.school label {
  font-family: 'Roboto Medium';
  background-color: #E7E4EF;
  color: #151C45;
  font-size: 16px;
  padding: 15px 40px;
  border-radius: 30px;
  margin-bottom: 10px;
  margin-right: auto;
}

.school h1 {
  font-family: 'Roboto Black';
  font-size: 45px;
  line-height: 60px;
  color: #151C45;
  margin-top: 20px;
  margin-bottom: 5px;
  padding-right: 10px;
}

.school h2 {
  font-family: 'Roboto Regular';
  font-size: 25px;
  line-height: 40px;
  color: #151C45;
  margin-bottom: 50px;
  padding-right: 10px;
  font-weight: 500;
}

.school img {
  height: 550px;
}

.school button {
  font-family: 'Roboto Regular';
  font-size: 19px;
  padding: 12px 37px;
  border: none;
  background-color: #F4F2FA;
  color: #03124B;
  cursor: pointer;
  border-radius: 12px;
  border: 2px solid #03124B;
  box-shadow: none;
  margin-right: auto;
  text-transform: none;
}


.school button:hover {
  background-color: #F4F2FA !important;
}


@media (max-width: 768px) {
  .school .body {
    padding: 0 20px;
  }

  .school label {
    margin-right: auto;
    margin-left: 20px;
  }
  
  .school h1 {
    font-family: 'Roboto Bold';
    text-align: center;
    font-size: 35px;
    line-height: 45px;
    margin: 40px 0;
    padding-right: 0px;
  }

  .school img {
    width: 85%;
    height: auto;
    padding-right: 20px;
  }

  .school h2 {
    font-family: 'Roboto Medium';
    text-align: center;
    font-size: 22px;
    line-height: 35px;
    margin: 30px 0;
    padding: 0px 20px;
  }

  .school button {
    font-family: 'Roboto Regular';
    margin: auto;
    padding: 10px 30px;
  }

}