.benefits {
  max-width: 1200px;
  margin: auto;
  margin-bottom: 120px;
}

.benefits h1 {
  font-family: 'Roboto';
  font-size: 48px;
  text-align: center;
  color: #03144A;
  margin-bottom: 80px;
}

.benefits .card {
  padding: 20px 25px;
  border-radius: 25px;
  min-height: 300px;
}

.benefits .card:hover {
  background-color: #FAF8FF;
}

.benefits .card-image {
  width: 105px;
  height: 105px;
}

.benefits .card-title {
  font-family: 'Roboto Bold';
  font-size: 20px;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #03144A;

}

.benefits .card-description {
  font-family: 'Roboto Regular';
  font-size: 17px;
  line-height: 26px;
  color: #03144A;

}

@media (max-width: 768px) {
  .benefits {
    padding: 0 20px;
  }

  .benefits h1 {
    text-align: center;
    font-size: 35px;
  }

  .benefits .card {
    padding: 25px 30px;
    margin: 0 30px;
    min-height: auto;
  }

  .benefits .card-title {
    font-size: 25px;
  }

  .benefits .card-description {
    font-size: 20px;
    line-height: 32px;
  }
}