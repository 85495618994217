.paymentApp {
  margin-bottom: 80px;
  padding-top: 0px;
  padding-bottom: 60px;
}

.paymentApp .body {
  max-width: 1000px;
  margin: auto;
  padding: 20px 0;
}

.paymentApp h1 {
  font-family: 'Roboto Black';
  font-size: 45px;
  line-height: 60px;
  color: #151C45;
  padding-right: 60px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.paymentApp h2 {
  font-family: 'Roboto Regular';
  font-size: 26px;
  line-height: 40px;
  color: #03124B;
  margin-bottom: 60px;
  padding-right: 60px;
  font-weight: 500;
}

.paymentApp .paymentMethods {
  height: 100px;
  margin-right: auto;
}

.paymentApp img {
  max-height: 650px;
}


@media (max-width: 768px) {
  .paymentApp .body {
    padding: 0 20px;
  }

  .paymentApp h1 {
    font-family: 'Roboto Bold';
    text-align: center;
    font-size: 35px;
    line-height: 45px;
    margin: 0 0 0 0;
    padding-right: 0;
  }

  .paymentApp h2 {
    font-family: 'Roboto Medium';
    text-align: center;
    font-size: 22px;
    line-height: 35px;
    margin: 40px 0 0 0;
    padding: 0px 30px;
  }

  .paymentApp img {
    width: 85%;
    height: auto;
    margin: 60px auto 0px auto;
  }

  .paymentApp img.mobile {
    margin-bottom: 0px;
  }

}